<template>
    <div>
        <strong>Model Importer Upload</strong>
        <br>

        <div class="form-group">
            <label>Part</label>
            <Typeahead cdi_type="part" v-bind:ID.sync="state.referenceID" />
        </div>

        <div>
            Double check the model numbers.
            <br>
            <br>
            {{ appState.fileUploadContextData.metadata }}
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import appStore from "@/store/App.store";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "ModelImport",
        components: {
            Typeahead
        },
        data() {
            return {
                state: fileUploadStore.state,
                appState: appStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            fileUploadStore.loadMetadata();
        }
    }
</script>

<style scoped>

</style>